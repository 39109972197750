<template>
  <v-app-bar absolute color="transparent" flat height="60">
    <v-container>
      <v-row align="center">
        <v-btn
          v-for="(item, i) in items"
          :key="i"
          :to="item.to"
          class="hidden-sm-and-down"
          min-height="48"
          text
        >
          <v-icon left size="20" v-text="item.icon" />
          <span v-text="item.text" />
        </v-btn>
      </v-row>
      <v-row> <v-spacer></v-spacer><dropdown-language align="right" /> </v-row>
    </v-container>
  </v-app-bar>
</template>

<script>
export default {
  name: "PagesCoreAppBar",
  data: () => ({
    items: [],
  }),
  components: {
    DropdownLanguage: () => import("@/components/ui/DropdownLanguage"),
  },
};
</script>
